.login {
    @apply bg-body;
}

.login-input {
    @apply block w-full px-4 py-2 mt-2 text-dark font-normal bg-muted border-gray-500 focus:border-dark-theme focus:outline-none transition-colors duration-200 ease-in-out;
}

/*.password-input {
    @apply border-gray-500 border-y-2 border-l-2;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}*/

.show-password {
    @apply rounded-md text-white;
}

.error-message {
    @apply text-sm text-error font-semibold mt-1;
}


