.report-card {
    @apply w-full bg-dark-theme text-white rounded-lg flex items-center p-4 mb-3 xl:mb-0;
}

@media (max-width: 388px) {
    .report-card .contet-report {
        @apply text-center w-full;
    }
}

.admin-card {
    @apply border rounded-lg relative flex flex-col min-w-0;
    word-wrap: break-word;
}

.icon-card {
    @apply block;
}

@media (max-width: 388px) {
    .icon-card {
        @apply hidden;
    }
}

