@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    scroll-behavior: smooth;
}

html, 
body,
#App,
main {
    @apply h-full;
    background-color: #EDF2F7 !important;
}

/* #App,
main {
    @apply h-full;
} */

body {
    @apply font-mulish overflow-auto;
}

.item-sidebar {
    @apply w-full flex items-center justify-start rounded-md mt-2 transition-colors cursor-pointer hover:bg-slate-800 text-white text-base gap-x-4 py-2;
    padding-left: 19px;
    padding-right: 19px;
}

.rdt_Table,
.rdt_TableHead,
.rdt_TableRow {
    @apply text-base !important;
}

.rdt_Table {
    @apply border border-secondary;
}

.rdt_TableHeadRow {
    @apply bg-secondary text-white !important;
}

.rdt_TableHeadRow .rdt_TableCol,
.rdt_TableBody .rdt_TableRow .rdt_TableCell {
    @apply justify-center font-bold;
}

.rdt_TableHeadRow {
    @apply py-3;
}

.rdt_TableRow {
    @apply py-3;
}

.rdt_TableCell {
    @apply w-auto;
}

.rdt_TableRow:not(:last-of-type) {
    @apply border-b border-secondary !important;
}

.rdt_Pagination {
    @apply bg-transparent text-base border-none !important;
}

.modal-component-container {
    @apply fixed inset-0 z-30 overflow-y-auto overflow-x-hidden bg-dark bg-opacity-75;
}

.modal-flex-container {
    @apply flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.modal-bg-container {
    @apply fixed inset-0 bg-gray-700 bg-opacity-75;
}

.modal-container {
    @apply inline-block align-bottom my-4 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-3xl w-full;
}

.modal-wrapper {
    @apply bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4;
}

.modal-body {
    @apply w-full mt-4;
}

.modal-wrapper-flex {
    @apply sm:flex sm:items-center;
}

.modal-icon {
    @apply mx-auto text-white flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-dark-theme sm:mx-0 sm:h-10 sm:w-10;
}

.modal-content {
    @apply text-center mt-3 sm:mt-0 sm:ml-4 sm:text-left;
}

.modal-title {
    @apply text-lg font-bold text-gray-900;
}

.modal-text {
    @apply mt-2;
}

.modal-text p {
    @apply text-gray-500 text-sm;
}

.modal-actions {
    @apply border-t border-gray-200 px-4 py-3 sm:px-6 sm:flex sm:justify-end;
}

.modal-space-container {
    @apply hidden sm:inline-block sm:align-middle xl:h-screen;
}

.bg-fade-in {
    @apply animate-fadeIn ease-in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.bg-fade-out {
    @apply animate-fadeOut ease-in;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.modal-scale-in {
    @apply animate-scaleIn ease-in;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.modal-scale-out {
    @apply animate-scaleOut ease-in;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.radio-form {
    @apply flex p-3 text-gray-600 font-semibold transition-colors bg-white border border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-theme peer-checked:ring-2 peer-checked:bg-theme peer-checked:text-white peer-checked:border-theme;
}

.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
}

input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
}

input:checked + .toggle-bg {
    @apply bg-theme border-theme;
}

.table-results .data-table-extensions-filter {
    @apply mb-4;
}

.table-results .data-table-extensions-filter .filter-text {
    @apply bg-white bg-opacity-50 rounded border border-gray-300 focus:border-secondary focus:bg-white focus:ring-2 focus:ring-input text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
}

.table-results .data-table-extensions-filter .icon {
    @apply float-left block w-5 h-6 bg-no-repeat mx-3 py-5;
    background-image: url('../svg/search.svg');
    background-position: left center;
}

.ck-editor__editable {
    @apply h-64;
}

.ck-editor-text-area .ck-content ul {
    list-style: disc;
    margin: revert;
    padding: revert;
}

.ck-editor-text-area .ck-content ol {
    list-style: auto;
    margin: revert;
    padding: revert;
}

.ck-editor-text-area .ck-content h2 {
    font-size: 1.5em;
    font-weight: bold;
}

.ck-editor-text-area .ck-content h3 {
    font-size: 1.17em;
    font-weight: bold;
}

.ck-editor-text-area .ck-content h4 {
    font-weight: bold;
}

.btn-report {
    @apply flex justify-between items-center bg-theme w-full px-5 py-4 rounded-lg lg:text-xl text-base text-center text-white font-bold transition-colors hover:bg-dark-theme;
}
